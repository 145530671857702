import React from 'react';
import './DataStyles.css';

const Data = () => {
    return  (
        <div className='data'>
            <div className='container'>
                <div className='content'>
                    <h2><span>Website</span> Portfolio</h2>
                    <p>Text</p>
                    <div><button>Explore</button></div>
                </div>
            </div>

        </div>
    )
}

export default Data;