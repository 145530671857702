import React from 'react';
import './RecoveryStyles.css';

const Recovery = () => {
    return  (
        <div className='recovery'>
          <div className='container'>
            <div className='content'>
                <h2><span>Website</span> Portfolio</h2>
                <p>Text</p>
                <div>
                    <button>Explore</button>
                </div>
            </div>

          </div>

        </div>
    )
}

export default Recovery;