import React from 'react';
import './CloudStyles.css';

const Cloud = () => {
    return  (
        <div className='cloud'>
            <div className='container'>
                <div className='content'>
                    <h2><span>Web Development</span> Services</h2>
                    <p>text</p>
                    <div><button>Learn More</button></div>
                </div>
            </div>

        </div>
    )
}

export default Cloud;